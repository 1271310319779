export const dimesionEnum = {
  date: {
    name: '日期',
    prop: 'date',
    width: 120,
    value: 'date'
  },
  creativeUrl: {
    name: '素材内容',
    prop: 'cid',
    width: 220,
    value: 'cid'
  },
  appName: {
    name: '应用',
    prop: 'appName',
    width: 150,
    value: 'app_id'
  },
  placeName: {
    name: '广告位',
    prop: 'placeName',
    width: 150,
    value: 'place_id'
  },
  platName: {
    name: '广告平台',
    prop: 'platName',
    width: 150,
    value: 'plat_id'
  },
  sourceName: {
    name: '广告源',
    prop: 'sourceName',
    width: 150,
    value: 'source_id'
  },
  osName: {
    name: '操作系统',
    prop: 'osName',
    width: 80,
    value: 'os'
  },
  packageName: {
    name: '产品包名',
    prop: 'packageName',
    width: 150,
    value: 'appBundle'
  },
  productName: {
    name: '推广产品',
    prop: 'productName',
    width: 150,
    value: 'appName'
  },
  type: {
    name: '素材类型',
    prop: 'type',
    width: 80,
    value: 'type'
  }
}

export const dimesionList = [
  {
    key: dimesionEnum.date.value,
    name: dimesionEnum.date.name,
    prop: dimesionEnum.date.prop,
    width: dimesionEnum.date.width
  },
  {
    key: dimesionEnum.creativeUrl.value,
    name: dimesionEnum.creativeUrl.name,
    prop: dimesionEnum.creativeUrl.prop,
    width: dimesionEnum.creativeUrl.width
  },
  {
    key: dimesionEnum.appName.value,
    name: dimesionEnum.appName.name,
    prop: dimesionEnum.appName.prop,
    width: dimesionEnum.appName.width
  },
  {
    key: dimesionEnum.placeName.value,
    name: dimesionEnum.placeName.name,
    prop: dimesionEnum.placeName.prop,
    width: dimesionEnum.placeName.width
  },
  {
    key: dimesionEnum.platName.value,
    name: dimesionEnum.platName.name,
    prop: dimesionEnum.platName.prop,
    width: dimesionEnum.platName.width
  },
  {
    key: dimesionEnum.sourceName.value,
    name: dimesionEnum.sourceName.name,
    prop: dimesionEnum.sourceName.prop,
    width: dimesionEnum.sourceName.width
  },
  {
    key: dimesionEnum.packageName.value,
    name: dimesionEnum.packageName.name,
    prop: dimesionEnum.packageName.prop,
    width: dimesionEnum.packageName.width
  },
  {
    key: dimesionEnum.productName.value,
    name: dimesionEnum.productName.name,
    prop: dimesionEnum.productName.prop,
    width: dimesionEnum.productName.width
  },
  {
    key: dimesionEnum.osName.value,
    name: dimesionEnum.osName.name,
    prop: dimesionEnum.osName.prop,
    width: dimesionEnum.osName.width
  },
  {
    key: dimesionEnum.type.value,
    name: dimesionEnum.type.name,
    prop: dimesionEnum.type.prop,
    width: dimesionEnum.type.width
  }
]
